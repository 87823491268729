import React from "react";
import { dietplan, PaymentTransactionDietPlans } from "../../../actions/index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { firebase } from "../../firebase";
import { ButtonGroup, Button } from "react-bootstrap";
import { ToastContainer, toast, Flip } from "react-toastify";
import { toastConfig } from "../../Custom/ToastConfig";
import { Modal } from "react-bootstrap";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import "../../../css/yogaform.css";
import "react-toastify/dist/ReactToastify.css";

class YogaForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      service: "",
      age: "",
      gender: "",
      medical: "",
      height: "",
      weight: "",
      activity: "",
      diet: "",
      goal: "",
      name: localStorage.getItem('name')?localStorage.getItem('name'):"",
      phone: localStorage.getItem('mobile')?localStorage.getItem('mobile'):"",
      plan: props.User.plan,
      amount: props.User.amount,

      submitted: false
    };
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  async handleClick(e) {
    e.preventDefault();
    this.setState({ submitted: true }, () => {
      if (
        this.state.gender &&
        this.state.service &&
        this.state.diet &&
        this.state.activity &&
        this.state.medical &&

        this.state.age &&
        this.state.height &&
        this.state.weight &&
        this.state.goal
      ) {
        var add = this.state;
        this.props.TrainerService(add);
        delete this.state.submitted;

        this.handleReset();
        this.props.close();
      } else {
        toast("Please input all the required data !!", toastConfig);
      }
    });
  }
  handleReset = () => {
    this.setState({
      service: "",
      age: "",
      gender: "",
      medical: "",
      height: "",
      weight: "",
      activity: "",
      diet: "",
      goal: "",
      period: "",
      plan: ""
    });
  };
  handleClickforState() {
    this.setState({
      name: "",
      phone: ""
    });
  }
  myself() {
    var self = this;
    self.setState({
      name: self.props.userDetail.name,
      phone: self.props.userDetail.mobile
    });
  }


  render() {
    return (
      <div className="container-fluid">
        <div className="scroll">
        <ValidatorForm
                onSubmit={() => this.handleClick()}
                className="formatfitness col-sm-12 col-xs-12 col-md-12 col-lg-12"
              >
                <div class="row col-sm-12 col-xs-12 col-md-12 col-lg-12">
                  <div class="col-sm-12 ">
                        <div className="d-flex flex-column workoutpreference">
                        <ButtonGroup>
                          <Button
                            name="service"
                            value="myself"
                            checked={this.state.service === "myself"}
                            onClick={this.handleInputChange}
                            onChange={() => this.myself()}
                            className={
                              this.state.service === "myself" ? "inGym" : null
                            }
                            style={{ background: "gray", border: 0 }}
                          >
                            My Self
                          </Button>
                          <Button
                            value="forothers"
                            name="service"
                            checked={this.state.service === "forothers"}
                            onClick={this.handleInputChange}
                            onChange={() => this.handleClickforState()}
                            className={
                              this.state.service === "forothers"
                                ? "inGym"
                                : null
                            }
                            style={{ background: "gray", border: 0 }}
                          >
                            For Others
                          </Button>
                        </ButtonGroup>
                      </div>
                      <br />
                    <div className="d-flex flex-column workoutpreference">
                    <ButtonGroup>
                      <Button
                        name="gender"
                        value="male"
                        checked={this.state.gender === "gender"}
                        onClick={this.handleInputChange}
                        className={
                          this.state.gender === "male" ? "inGym" : null
                        }
                        style={{ background: "gray", border: 0 }}
                      >
                        Male
                      </Button>
                      <Button
                        value="female"
                        name="gender"
                        checked={this.state.gender === "female"}
                        onClick={this.handleInputChange}
                        className={
                          this.state.gender === "female"
                            ? "inGym"
                            : null
                        }
                        style={{ background: "gray", border: 0 }}
                      >
                        Female
                      </Button>
                    </ButtonGroup>
                  </div>
                  <br />
                      <div>
                        <div className="form-group ">
                          <div className="inputfield">
                            <TextValidator
                              validators={["required"]}
                              errorMessages={"Please enter your Name !"}
                              type="text"
                              placeholder="Name * "
                              className="form-control"
                              name="name"
                              onChange={this.handleInputChange}
                              value={this.state.name}
                              />
                          </div>
                        </div>

                        <div className="form-group ">
                          <div className="inputfield">
                            <TextValidator
                              validators={["required"]}
                              errorMessages={"Please enter your Phone no !"}
                              type="number"
                              placeholder="Phone *"
                              className="form-control"
                              name="phone"
                              onChange={this.handleInputChange}
                              value={this.state.phone}
                              />
                          </div>
                        </div>
                      </div>
                   
                    <div className="form-group">
                      <div className="inputfield">
                        <TextValidator
                          validators={[
                            "required",
                            "minNumber:18",
                            "maxNumber:100"
                          ]}
                          type="number"
                          placeholder="Age *"
                          className="form-control"
                          name="age"
                          onChange={this.handleInputChange}
                          value={this.state.age}
                          errorMessages={"Age should be between 18 - 100"}
                        />
                      </div>
                    </div>
                    <div className="form-group ">
                      <div className="inputfield">
                  <select id="height" name="height" value={this.state.height}  className="form-control dropdown " onChange={this.handleInputChange}>
                    <option value="">Select height</option>
                    <option value="4">4.0 Feet</option>
                    <option value="4.1">4.1 Feet</option>
                    <option value="4.2">4.2 Feet</option>
                    <option value="4.3">4.3 Feet</option>
                    <option value="4.4">4.4 Feet</option>
                    <option value="4.5">4.5 Feet</option>
                    <option value="4.6">4.6 Feet</option>
                    <option value="4.7">4.7 Feet</option>
                    <option value="4.8">4.8 Feet</option>
                    <option value="4.9">4.9 Feet</option>
                    <option value="4.10">4.10 Feet</option>
                    <option value="4.11">4.11 Feet</option>
                    <option value="5.0">5.0 Feet</option>
                    <option value="5.1">5.1 Feet</option>
                    <option value="5.2">5.2 Feet</option>
                    <option value="5.3">5.3 Feet</option>
                    <option value="5.4">5.4 Feet</option>
                    <option value="5.5">5.5 Feet</option>
                    <option value="5.6">5.6 Feet</option>
                    <option value="5.7">5.7 Feet</option>
                    <option value="5.8">5.8 Feet</option>
                    <option value="5.9">5.9 Feet</option>
                    <option value="5.10">5.10 Feet</option>
                    <option value="5.11">5.11 Feet</option>
                    <option value="6.0">6.0 Feet</option>
                    <option value="6.1">6.1 Feet</option>
                    <option value="6.2">6.2 Feet</option>
                    <option value="6.3">6.3 Feet</option>
                    <option value="6.4">6.4 Feet</option>
                    <option value="6.5">6.5 Feet</option>
                    <option value="6.6">6.6 Feet</option>
                    <option value="6.7">6.7 Feet</option>
                    <option value="6.8">6.8 Feet</option>
                    <option value="6.9">6.9 Feet</option>
                    <option value="6.10">6.10 Feet</option>
                    <option value="6.11">6.11 Feet</option>
                    <option value="7.0">7 Feet</option>
                  </select>
                       
                      </div>
                    </div>
                    <div className="form-group ">
                      <div className="inputfield">
                        <TextValidator
                          validators={["required"]}
                          errorMessages={"Please enter your weight !"}
                          type="number"
                          placeholder="Weight *  (Kgs)"
                          className="form-control"
                          name="weight"
                          onChange={this.handleInputChange}
                          value={this.state.weight}
                        />
                      </div>
                    </div>
                    <div className="form-group ">
                      <div className="inputfield">
                        <TextValidator
                          validators={["required"]}
                          errorMessages={"Please enter your Goal !"}
                          type="text  "
                          placeholder="Goal *"
                          className="form-control"
                          name="goal"
                          onChange={this.handleInputChange}
                          value={this.state.goal}
                        />
                      </div>
                    </div>
                   
                  </div>
                </div>

                <div>
                  <label
                    style={{ color: "rgb(233, 197, 137)" }}
                    className="preferreddiet"
                  >
                    Preferred Diet
                  </label>

                  <div className="d-flex flex-column">
                    <ButtonGroup>
                      <Button
                        name="diet"
                        value="veg"
                        checked={this.state.diet === "veg"}
                        onClick={this.handleInputChange}
                        className={this.state.diet === "veg" ? "veg" : null}
                        style={{ background: "gray", border: 0 }}
                      >
                        Vegertain
                      </Button>
                      <Button
                        value="nonveg"
                        name="diet"
                        checked={this.state.diet === "nonveg"}
                        onClick={this.handleInputChange}
                        className={
                          this.state.diet === "nonveg" ? "nonveg" : null
                        }
                        style={{ background: "gray", border: 0 }}
                      >
                        Non vegertain
                      </Button>
                    </ButtonGroup>
                    <br />
                  </div>
                </div>

                <div>
                  <label
                    style={{ color: "rgb(233, 197, 137)" }}
                    className="dailylifestyle"
                  >
                    Daily Life Style Physical Activity{" "}
                  </label>

                  <ButtonGroup>
                    <Button
                      name="activity"
                      value="low"
                      onClick={() => {
                        this.setState({ activity: "low" });
                      }}
                      className={this.state.activity === "low" ? "low" : null}
                      style={{ background: "gray", border: 0 }}
                    >
                      Low Active
                    </Button>
                    <Button
                      name="activity "
                      value="sedentary"
                      onClick={() => {
                        this.setState({ activity: "sedantary" });
                      }}
                      className={
                        this.state.activity === "sedantary" ? "sedantary" : null
                      }
                      style={{ background: "gray", border: 0 }}
                    >
                      Sedentary Active
                    </Button>
                    <Button
                      type="button"
                      value="moderate"
                      name="activity"
                      onClick={() => {
                        this.setState({ activity: "moderate" });
                      }}
                      className={
                        this.state.activity === "moderate" ? "moderate" : null
                      }
                      style={{ background: "gray", border: 0 }}
                    >
                      Moderate Active
                    </Button>
                  </ButtonGroup>
                  <br />
                </div>

                <div>
                  <br />
                  <label
                    style={{ color: "rgb(233, 197, 137)" }}
                    className="medicalcondition"
                  >
                    Do You have Any Medical Condition or Any Allergic with Any
                    Food?If yes Please describe Here!
                  </label>
                  <textarea
                    type="text"
                    className="form-control "
                    name="medical"
                    onChange={this.handleInputChange}
                    value={this.state.medical}
                  />
                </div>
                <br />
                <div className="marginforyogabutton">
                  <center>
                    <button
                      type="submit"
                      style={{ background: "#E9C589 !important" }}
                      className="enroll_buttonforyoga button-slanted"
                      onClick={e => this.handleClick(e)}
                    >
                      <span className="butto-slanted-content">Compute Assessment</span>
                    </button>
                  </center>
                </div>
              </ValidatorForm>
              <ToastContainer transition={Flip} />
          {/* <div className="container">
            <div className="enroll_card col-sm-12 col-xs-12 col-md-12 col-lg-12">
              
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    User: state.dietPlan,
    userDetail: state.userDetail.userlogindetails
  };
};
const mapDispatchToProps = dispatch => {
  return {
    TrainerService: trainerservice => {
      dispatch(dietplan(trainerservice));
    },
    paymentTransactionId: paymentid => {
      dispatch(PaymentTransactionDietPlans(paymentid));
    }
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(YogaForm)
);
