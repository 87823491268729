import React from "react";
import "../../css/Footer.css";
import LazyLinePainter from "lazy-line-painter";
import CorporatePlan from "../Landing/CorporatePlan";
import { Card, Modal, Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { NavLink } from "reactstrap";
import NatalForm from "../Landing/NatalForm";
class Footer extends React.Component {
  state = {
    show: false,
    view: false
  };

  componentDidMount() {
    let els = {
      logo: document.querySelector("#lion")
    };
    let myAnimation;
    myAnimation = new LazyLinePainter(els.logo, {
      strokeWidth: 2.4,
      strokeCap: "round",
      strokeColor: "#a9d372",
      ease: "easeInOutSine",
      drawSequential: true,
      repeat: -1,
      delay: 500
    });
    myAnimation.paint();
    // myAnimation.on("start", onStart);
  }
  changeshow() {
    this.setState({
      show: false
    });
  }
  changeview() {
    this.setState({
      view: false
    });
  }
  callcorporate() {
    this.setState({
      show: true
    });
  }
  callnatal() {
    this.setState({
      view: true
    });
  }
  render() {
    return (
      <section className="foot">
        <div className="container-fluid">
          <div
            className="row container-row"
            style={{ alignItems: "flex-start" }}
          >
            <div className="bowootfoot col-sm-3">
              <h4 className="foothead">
                <strong>Fitfinity</strong>
              </h4>
              <ul className="itemsfoot">
                <a onClick={() => this.props.history.push("/about")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>About us</li>
                </a>
                <a  onClick={() => this.props.history.push("/diet")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Packages</li>
                </a>
                <a onClick={() => this.props.history.push("/diet")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>How it Works</li>
                </a>
                <a href="#">
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Careers</li>
                </a>
                <a onClick={() => this.props.history.push("/contact")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Contact us</li>
                </a>
                <a href="#">
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Blogs</li>
                </a>
              </ul>
            </div>

            <div className="servicesfoot col-sm-3">
              <h4 className="foothead">
                <strong>Services</strong>
              </h4>
              <ul className="itemsfoot service_mq_foot">
                <a onClick={() => this.props.history.push("/booksession")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Fitness Trainer</li>
                </a>
                <a onClick={() => this.props.history.push("/booksession")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Yoga Trainer</li>
                </a>
                <a onClick={() => this.props.history.push("/diet")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Diet Plans</li>
                </a>
                <a onClick={() => this.props.history.push("/booksession")} >
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Fitness Plans</li>
                </a>
                <a onClick={() => this.props.history.push("/institute")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>
                    Fitness/Nutritionist Certifications
                  </li>
                </a>
                <a onClick={() => {
                    this.callnatal();
                  }}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Pre/Post Natal exercise</li>
                </a>
                <a  onClick={() => {
                    this.callcorporate();
                  }}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Corporate Fitness</li>
                </a>
               
              </ul>
            </div>
            <div className="faqfoot col-sm-3">
              <h4 className="foothead"> 
                <strong>FAQ</strong>
              </h4>
              <ul className="itemsfoot faq_item_mq">
                <a onClick={() => this.props.history.push("/TermsConditions")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Terms <span style={{fontFamily: "Roboto"}}>&</span> Conditions</li>
                </a>
                <a onClick={() => this.props.history.push("/Privacy")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Privacy Policy</li>
                </a>
                <a onClick={() => this.props.history.push("/Refund")}>
                  <li style={{ color: "#D0A26E",  textTransform: "uppercase" }}>Refund Policy</li>
                </a>
              </ul>
              <img src={require("./../../assests/app_store.png")} style={{width: "40%", margin: "20px"}}/>
              <img src={require("./../../assests/google-play.png")} style={{width: "40%", margin: "20px"}}/>
            </div>
            <div className="corporatefoot col-sm-3">
             

              <img
                          src="https://cdn.animaapp.com/projects/664e4c4c9c55ddf5654a5c88/releases/6663568a7e286e30e29dcfeb/img/layer-1.svg"
                          alt="asset1"
                        />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 79.46 79.51"
                data-llp-composed="true"
                id="lion"
                className="lazy-line-painter"
              >
                
              </svg>
              
            </div>

            <Modal show={this.state.show} onHide={() => this.changeshow()}>
              <Modal.Header closeButton></Modal.Header>
              <CorporatePlan onHide={() => this.changeshow()} />
            </Modal>

            <Modal
              show={this.state.view}
              onHide={() => this.changeview()}
              className="col-sm-12"
            >
              <Modal.Header closeButton></Modal.Header>
              <NatalForm onHide={() => this.changeview()} />
            </Modal>
          </div>
        </div>
      </section>
    );
  }
}
export default withRouter(Footer);
