import React from "react"


class History extends React.Component{
    constructor(){
        super()
        this.state={
            result:[],
            result2:[],
            uid:'' 
        }
    }

    componentDidMount(){
     
    }

    render(){
        
        return(
            <div className="col render">
                <span className="dashboard-logo">HISTORY</span> 
                <div className="padding-top">
                    <div className="view-con">
                    <div>
                            <table className="table-service">
                                <tr style={{fontWeight:"100"}}>
                                    <th className="table-padding">Requested By</th>
                                    <th className="table-padding">Time Stamp</th>
                                </tr>
                                <tbody>
                                    
                                </tbody>
                            </table>
                        </div>

                    </div>
            </div>
            </div>
        )
    }
}

export default History