const DietProducts = [
  {
    id: 1,
    content: "WEIGHT GAIN OR MUSCLE BUILDING PLAN",
    oldPrice: "1999",
    newPrice: "499",
    image: "gain_weight.png",
    description:
      " Fitfinity provides the World’s best and effective diet plans to achieve your muscle building or weight gain goal in minimal time in the easiest possible ways"
  },
  {
    id: 2,
    content: "WEIGHT LOSS OR FAT LOSS DIET PLAN",
    oldPrice: "2999",
    newPrice: "999",

    image: "keto.png",
    description:
      "The Weight loss diet is a low-fat, adequate-protein, low-carbohydrate diet forces the body to burn fats rather than carbohydrates. Over 20+ studies concludes that this diet is most effective for weight loss and also provides improvised health by keeping your body in a metabolic state known as ketosis. This may even have benefits against Diabetes, Cancer, Epilepsy & Alzheimer’s medical conditions etc."
  },
  {
    id: 3,
    content: "THYROID AND DIBETIC PLAN",
    oldPrice: "3999",
    newPrice: "1999",
    image: "vegan.png",
    description:
      "People choose to follow diet due to various reasons, thyroid AND diabities is on of them. Fitfinity will provide the World’s best and most interesting diet for you to stay healthy."
  },
  {
    id: 4,
    content: "MEAL PLAN FOR YOUR FAMILY",
    oldPrice: "6999",
    newPrice: "3499",
    image: "healthy.png",
    description:
      "Fitfinity will provide you the diet that helps to maintain or improve overall health Of your family. This diet provides the body with essential nutrition: fluid, macronutrients, micronutrients, and adequate calories on your daily food consumptions which is needed for building a healthy lifestyle"
  },
 
];
export default DietProducts;
