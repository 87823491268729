import React from "react";
import { connect } from "react-redux";
import { updateUserStep } from "../../actions/index";
import Header from "../Header";
import PickServiceDate from "./PickServiceDate";
import UserCategory from "./UserCategory";
import PickServiceTime from "./PickServiceTime";
import PickTrialTime from "./PickTrialTime";
import PickServicePackage from "./PickServicePackage";
import PickServiceDeatails from "./PickServiceDeatails";
import PickServiceSuccess from "./PickServiceSuccess";
import TypeOfService from "./TypeOfService";
import Address from "./Address";
import { ProgressBar } from "react-bootstrap";
import { capitalize } from "./../Custom/Capitalize";

class BookSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      times: []
    };
  }
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };
  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  callParentTomo = data1 => {
    this.setState({
      times: data1
    });
    console.log(this.state.times);
  };

  callParent = data => {
    this.setState({
      times: data
    });
  };
  componentDidMount() {
    const user = localStorage.getItem('token');

    if(!user){
      this.props.history.replace("/login");
    }
    if (this.props.service) {
      this.setState({
        step: 1
      });
    }
  }
  render() {
    const { step } = this.state;
    return (
      <div className="gym-background background-watermark">
        {this.props.userRenderPage === "BookSession" ? null :''}
        <div className="book_index">
          <div class="container MQ_Tab_allign book_mq">
            <div class="row MQ_allign book_mq">
              <div className="col book_how_it_works">
                <div className="enroll-now-container bookhowcontainer">
                  <ul>
                    <strong>How it works</strong>
                    <li>1. Choose the service</li>
                    <li>2. Book Trial Session at 99/-(Refundable*)</li>
                    <li>3. Make payment and sit back.</li>
                    <li>4. We will send our best professional. If you like the session go ahead with our suitable package. </li>
                  </ul>
                </div>
              </div>
            
              <div className="trainer_container box-image booksession_container col-sm-7 col-md-7 col-xl-7 col-lg-7">
                <div className="trainer_header_container">
                  
                  {(this.props.service === 'FITNESS') ? (
                    <h5>Personal Trainer at Home</h5>
                  ) : (
                    <h5>Yoga Trainer at Home</h5>
                  )}
                </div>{" "}
                <ProgressBar
                  now={(this.state.step * 100) / 9}
                  className="progressStyle"
                />
                <br />
                {(() => {
                  switch (step) {
                    case 0:
                      return <TypeOfService nextStep={this.nextStep} />;
                    case 1:
                      return <PickServiceTime nextStep={this.nextStep} />;
                    case 2:
                      return (
                        <PickServicePackage
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                      />
                      );
                    case 3:
                      return (
                        <Address
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                      />
                      );
                    case 4:
                      return (
                        <PickServiceDeatails
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                        />
                      );
                    case 5:
                      return (
                        <PickServiceSuccess
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                      />
                      );
                    case 6:
                      return (
                        <PickServiceSuccess
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                      />
                      );
                    case 7:
                      return (
                        <PickServiceSuccess
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                      />
                      );
                    case 8 || 9:
                      return (
                        <PickServiceSuccess
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                      />
                      );
                  }
                })()}
              </div>
            </div>
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = State => {
  return {
    userRenderPage: State.userDetail.userpage,
    service: State.serviceState.services
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateStep: step => {
      dispatch(updateUserStep(step));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookSession);
