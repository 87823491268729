import React from "react";
class HowitWorks_Onclick extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <ul
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            margin: "14px",
            lineHeight: "1.5rem",
            color: "#E9C589 !important"
          }}
        >
          <li style={{ textAlign: "left" }}>
            <strong>1.</strong> Choose the service
          </li>
          <li style={{ textAlign: "left" }}>
            <strong>2.</strong>Book Trial Session at 99/-
            (Refundable*)
          </li>
          <li style={{ textAlign: "left" }}>
            <strong>3.</strong> Make payment and sit back{" "}
          </li>

          <li style={{ textAlign: "left" }}>
            <strong>4.</strong> We will send our best professional. If you like
            the session go ahead with our suitable package.
          </li>
        </ul>
        <div>
          <button
            className="howitcomponent_button"
            onClick={() => this.props.closehowitworks_Onclick()}
          >
            Done
          </button>
        </div>
      </div>
    );
  }
}

export default HowitWorks_Onclick;
