const FitnessDietProd = [
  {
    id: 1,
    content: "MUSCLE BUILDING    ",
    oldprice: "999",
    Price: "499",
    description: "something",

    image: "woman.png"
  },

  {
    id: 2,
    content: "WEIGHT LOSS   ",
    oldprice: "999",
    Price: "499",
    description: "something",
    image: "muscle.png"
  }
];
export default FitnessDietProd;
