import React from "react";
import Footer from "../Footer/Footer";
import Testimonials from "./Testimonials";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./../../css/Home.css";
import "./../../css/howitworks.css";
import "./../../css/icon_flat.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Header from "../Header";
import { Modal } from "react-bootstrap";

import {
  RegisterService,
  SplashState,
  userLoginDetails,
  Description,
  type_of_diet
} from "./../../actions/index";
import Splash from "../Custom/Splash";
import Requestform from "./Requestform";
import HowitWorks from "./HowitWorks";
import HowitWorks_Onclick from "./HowitWorks_Onclick";
import CorporatePlan from "./CorporatePlan";
import { functions_for_users } from "../../function_constant";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: true,
      howitworks_onclick: false,
      showCorporate: false
    };
  }

  fitnessService = () => {
    this.props.typeOfService("FITNESS");
    this.showhowitworks();
  };
  dietService = () => {
    this.props.typeOfService("DIET");
    if (localStorage.getItem('token')) {
      this.props.getdescription();
    } else {
      this.props.getdescription("some content");
    }

    this.props.history.push("/SelectService");
  };
  yogaService = () => {
    this.props.typeOfService("YOGA");
    this.showhowitworks();
  };

  showhowitworks() {
    this.setState({
      view: true
    });
  }

  changeview() {
    this.setState({
      view: false
    });
  }

  requestform() {
    this.setState({
      show: true
    });
  }
  changeshow() {
    this.setState({
      show: false,
      showCorporate: false
    });
  }

  callcorporate() {
    this.setState({
      showCorporate: true
    });
  }

  showHowitWorks_OnclickModal() {
    this.setState({
      howitworks_onclick: true
    });
  }
  hideHowitWorks_Onclick() {
    this.setState({
      howitworks_onclick: false
    });
  }
  dietplantypes = e => {
    // alert(e.target.id);
    this.props.diet_plan(e.target.id);
    this.props.history.push("/diet");
  };

  componentDidMount() {
    let self = this;
    // firebase.auth().onAuthStateChanged(function(user) {
    //   if (user && !self.props.service) {
    //     self
    //       .postData(functions_for_users.typeOfUser, { uid: user.uid })
    //       .then(data => {

    //         firebase
    //           .database()
    //           .ref("users")
    //           .child(user.uid)
    //           .child("profile")
    //           .on("value", function(snapshot) {
    //             self.props.storeProfile(snapshot.val());
    //           });
    //         if (data.result === "User" || self.props.User.userDetail.userpage) {
    //           switch (self.props.service) {
    //             case "FITNESS":
    //               return self.props.history.push("/booksession");
    //             case "YOGA":
    //               return self.props.history.push("/booksession");
    //           }
    //         }
    //       });
    //   } else {
    //     setTimeout(() => {
    //       self.setState({
    //         timer: false
    //       });
    //       self.props.splashState(self.state.timer);
    //     }, 3000);
    //   }
    // });
  }

  postData(url = ``, data) {
    return new Promise(function(resolve, reject) {
      fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "text/plain; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          "Accept-Encoding": "gzip"
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })
        .then(response => resolve(response.json()))
        .catch(err => {
          reject(err);
        }); // parses response to JSON
    });
  }

  render() {
    return (
      <div>
        {this.state.timer === true && this.props.splashState === true ? (
          <Splash />
        ) : (
          <div className="gym-background background-watermark">
            <div id="landing">
              <Header />
              <section className="container-fluid">
               
                <div className="row">
                  <div className="col-sm-3">
                   
                  </div>
                  <div className="enroll_main col-sm-5">
                    <h2  className="header-two">Get fitter at home</h2>
                    <p className="header-p">Start your wellness journey with Fitfinity. We offer personalized home training, yoga sessions, and custom diet plans tailored just for you.</p>
                    <a
                            className="enroll_button"
                            onClick={() => this.fitnessService()}
                          >
                            <span className="enroll-content">Explore Now </span>
                          </a>
                  </div>
                  <div className="col-sm-3">
                    
                  </div>
                  {this.state.show ? null : (
                    <span className="requestformalignmentformobile">
                      <a
                        className="requestformalignment"
                        onClick={() => this.requestform()}
                      >
                        <img
                          src={require("./../../assests/conversation.png")}
                        />
                      </a>
                    </span>
                  )}
                </div>
              </section>
              <section className="container-fluid">
                <div className="row">
                  <div className="col-sm-6">
                    <img src={require("./../../assests/step-1.png")} style={{width: "100%"}}/>
                  </div>
                  <div className="enroll_main col-sm-6">
                      <h2 className="header-three">Personal Strength training at home</h2>
                        <p className="header-p">Unlock your potential with our dynamic personal strength training sessions right in the 
                          comfort of your home. Our expert trainers bring the gym to you, ensuring you build muscle, boost endurance, and achieve your 
                          fitness goals without stepping out the door. Get personalised workouts tailored to your needs, 
                          and see transformative results that will keep you motivated and coming back for more.</p>
                      <a className="service_button" onClick={() => this.fitnessService()} >
                            <span className="enroll-content">Enroll Now </span>
                      </a>
                  </div>
                </div>
              </section>
              <section className="container-fluid">
                <div className="row">
                  
                  <div className="enroll_main col-sm-6">
                      <h2 className="header-three">Curated Diet plans
                      and nutrition</h2>
                        <p className="header-p">Fuel your body with our personally tailored diet plans and nutrition advice that doesn't strip away your tastebuds. Say goodbye to guesswork and hello to a healthier, more vibrant you! With our curated diet plans that perfectly complement your fitness regime, you stay energized, shed unwanted pounds, and maintain a balanced lifestyle, one day at a time. Eat smart, feel great, and watch your fitness dreams become a reality.</p>
                      <a className="service_button" onClick={() => this.dietService()} >
                            <span className="enroll-content">Enroll Now </span>
                      </a>
                  </div>
                  <div className="col-sm-6">
                    <img src={require("./../../assests/step-2.png")} style={{width: "100%"}}/>
                  </div>
                </div>
              </section>
              <section className="container-fluid">
                <div className="row">
                  <div className="col-sm-6">
                    <img src={require("./../../assests/step-3.png")} style={{width: "100%"}}/>
                  </div>
                  <div className="enroll_main col-sm-6">
                      <h2 className="header-three">Personal Yoga training at home</h2>
                        <p className="header-p">Unlock your potential with our dynamic personal strength training sessions right in the 
                          comfort of your home. Our expert trainers bring the gym to you, ensuring you build muscle, boost endurance, and achieve your 
                          fitness goals without stepping out the door. Get personalised workouts tailored to your needs, 
                          and see transformative results that will keep you motivated and coming back for more.</p>
                      <a className="service_button" onClick={() => this.yogaService()} >
                            <span className="enroll-content">Enroll Now </span>
                      </a>
                  </div>
                </div>
              </section>
            </div>
         
            {/* <section>
              <Testimonials />
            </section> */}

            {this.state.show ? (
              <Requestform
                onHide={() => this.changeshow()}
                show={this.state.show}
              />
            ) : null}

            <Modal
              show={this.state.view}
              onHide={() => this.changeview()}
              backdrop={"static"}
              keyboard={false}
              size="sm"
              className="col-sm-12 col-md-6"
            >
              <Modal.Header closeButton className="Howitworks_Header">
                <h3>How it works</h3>
              </Modal.Header>
              <HowitWorks closehowitworks={() => this.changeview()} />
            </Modal>

            <Modal
              show={this.state.howitworks_onclick}
              onHide={() => this.hideHowitWorks_Onclick()}
              backdrop={"static"}
              keyboard={false}
            >
              <Modal.Header closeButton className="Howitworks_Header">
                <h3>How it works</h3>
              </Modal.Header>
              <HowitWorks_Onclick
                closehowitworks_Onclick={() => this.hideHowitWorks_Onclick()}
              />
            </Modal>
            <Modal
              show={this.state.showCorporate}
              onHide={() => this.changeshow()}
            >
              <Modal.Header closeButton></Modal.Header>
              <CorporatePlan onHide={() => this.changeshow()} />
            </Modal>
            <Footer />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    currentuser: state.sessionState.authUser,
    User: state.userDetail,
    splashState: state.splashState.splashState
  };
};
const mapDispatchToProps = dispatch => {
  return {
    typeOfService: services => {
      dispatch(RegisterService(services));
    },
    getdescription: description => {
      dispatch(Description(description));
    },
    splashState: timer => {
      dispatch(SplashState(timer));
    },
    storeProfile: data => {
      dispatch(userLoginDetails(data));
    },
    diet_plan: typeofdiet => {
      dispatch(type_of_diet(typeofdiet));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
