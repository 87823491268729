import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Flip } from "react-toastify";
import "../../css/corporateplan.css";
import { connect } from "react-redux";
import { firebase } from "./../firebase";
import { withRouter } from "react-router-dom";
import { toastConfig } from "../Custom/ToastConfig";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

class NatalForm extends React.Component {
  state = {
    name: "",
    address: "",
    requirement: "",
    email: "",
    phone: "",
    show: true
  };
  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleClick = e => {
    if (
      this.state.name.trim() &&
      this.state.requirement.trim() &&
      this.state.email.trim()
    ) {
      let add = this.state;
      let self = this;
      return new Promise(function(resolve, reject) {
        fetch('https://fitfinitytrainer.com/admin/api/neo-enquiry', {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Accept-Encoding": "gzip"
          },
          body: JSON.stringify(add) // body data type must match "Content-Type" header
        }).then((response) => response.json()).then((responseData) => {
          console.log(responseData)
              if(responseData.status == "success"){
                toast(
                  "Your Information in received we will contact you soon!!",
                  toastConfig
                );
                self.handleReset();
                setInterval(() => {
                  this.props.onHide();
                }, 3000);
              }
    
              if(responseData.errors){  
                const errorData = responseData.errors;
                  Object.keys(errorData).forEach((field) => {
                      errorData[field].forEach((message) => {
                        toast(`${field.charAt(0).toUpperCase() + field.slice(1)}: ${message}`, toastConfig);
                      });
                  });
              }
        }).catch(err => {
            toast(err.message, toastConfig);
            reject(err);
          }); // parses response to JSON
      });
    }
  };

  handleReset = () => {
    this.setState({
      name: "",
      company: "",
      address: "",
      requirement: "",
      email: "",
      phone: ""
    });
  };

  render() {
    return (
      <div className="corporate_background">
        <h2 className="natalheading">
          <strong className="" style={{color: "#D0A26E"}}>Natal(Pre & Post)</strong>
        </h2>

        <div className="corporate-container">
          <ValidatorForm onSubmit={this.handleClick}>
            <div className="inputfieldsize">
            <div style={{width: "100%"}}>

              <TextValidator
                type="text"
                placeholder="Name *"
                className="form-control"
                name="name"
                validators={["required"]}
                onChange={this.handleInputChange}
                value={this.state.name}
                errorMessages={["Name is required"]}
              />
            </div>
            </div>
            <div className="inputfieldsize">
            <div style={{width: "100%"}}>

              <TextValidator
                type="text"
                placeholder="Address"
                className="form-control"
                name="address"
                onChange={this.handleInputChange}
                value={this.state.address}
              />
            </div>
            </div>

            <div className="inputfieldsize">
            <div style={{width: "100%"}}>

              <TextValidator
                type="email"
                placeholder="Email *"
                className="form-control"
                name="email"
                validators={["required", "isEmail"]}
                onChange={this.handleInputChange}
                value={this.state.email}
                errorMessages={[
                  "Please enter a valid email id to contact you !"
                ]}
              />
            </div>
            </div>
            <div className="inputfieldsize">
            <div style={{width: "100%"}}>

              <TextValidator
                type="text"
                placeholder="Phone *"
                className="form-control"
                name="phone"
                onChange={this.handleInputChange}
                value={this.state.phone}
                validators={["required"]}
                errorMessages={["Kindly enter Phone No."]}
              />

            </div>
            </div>
            <div className="inputfieldsize">
            <div style={{width: "100%"}}>

              <TextValidator
                type="text"
                placeholder="Requirements *"
                className="form-control"
                name="requirement"
                validators={["required"]}
                onChange={this.handleInputChange}
                value={this.state.requirement}
                errorMessages={["Kindly share your requirements "]}
              />
            </div>
            </div>
            <div
              className="form-group enroll_main"
              style={{ alignItems: "center" }}
            >
              <button type="submit" className="buttonsubmit button-slanted">
                Submit
              </button>
            </div>
            <div className="message_container col-sm-8">
              We are going to send our certified fitness experts for the trial
              sessions please discuss your medical conditions with them properly
              before the trail session and plan accordingly.
            </div>
          </ValidatorForm>
        </div>
        <ToastContainer transition={Flip} style={{ top: "0%" }} />
      </div>
    );
  }
}

export default withRouter(connect()(NatalForm));
