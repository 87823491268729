import React from "react";
import "../../css/termsAndConditions.css";
import Header from "../Header/index";
import Footer from "../Footer/Footer";
class Contact extends React.Component {
  render() {
    return (
      <>
        <div className="termsAndConditionsContainer">
          <Header />

          <div className="container-row">
            <div className="col-sm-12 topMargin">
              <div className="TermsCenter">
                <h1 className="termsHeader">Contact Us</h1>

                {/* <div className="topParagraph">
                  Read our terms below to learn more about your rights and
                  responsibilities as a Fitfinity user.
                </div> */}
              </div>
              <img
                className="termsandConditionsImage"
                src={require("../../assests/hand.png")}
                // style={{ width: "30%" }}
              />
            </div>
          </div>
          <hr />

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-2"></div>
              <div className="col-sm-8">
                <div className="feedback">
                  
                </div>
                <div className="feedback">
               Please feel free to contact us via email at contact@fitfinitytrainer.com
                </div>
                
              </div>
              <div className="col-sm-2"></div>
            </div>
          </div>
        </div>
        <br />
        <Footer />
      </>
    );
  }
}

export default Contact;
