import React from "react"

class PushNotification extends React.Component{
    render(){
        return(
            <div className="col render">
                <span className="dashboard-logo">PUSH NOTIFICATION</span>
            </div>
        )
    }
}

export default PushNotification