import React from "react";

class ShopNow extends React.Component {
  render() {
    return (
      <div>
        <center>Shop Now (coming Soon)</center>
      </div>
    );
  }
}
export default ShopNow;
