import React from "react";
import TrainerDocuments from "../TrainerRegistrations/TrainerDocuments";
import TrainerExperience from "../TrainerRegistrations/TrainerExperience";
import TrainerDetails from "../TrainerRegistrations/TrainerDetails";
import TrainerBankDetails from "../TrainerRegistrations/TrainerBankDetails";
import { connect } from "react-redux";
import { updateStep, userLoginDetails } from "../../actions/index";
import Header from "../Header";
import Footer from "../Footer/Footer";

import Typeoftrainer from "./Typeoftrainer";
import Success from "./Success";
import TrainerBasicDetails from "./TrainerBasicDetails";

import "../../css/Trainer.css";
import { ProgressBar } from "react-bootstrap";
class TrainerRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [{}, {}, {}, {}, {}, {}, {}]
    };
  }

  nextPage = () => {
    this.props.updateStep(this.props.page + 1);
  };

  previousPage = () => {
    this.props.updateStep(this.props.page - 1);
  };

  componentDidMount() {
    let self = this;
    // firebase.auth().onAuthStateChanged(function(user) {
    //   if (user) {
    //     firebase
    //       .database()
    //       .ref("users")
    //       .child(user.uid)
    //       .child("profile")
    //       .on("value", function(snapshot) {
    //         console.log(snapshot.val());
    //         self.props.storeProfile(snapshot.val());
    //       });
    //   }
    // });
  }

  render() {
    const { page } = this.props;
    return (
      <div className="background-watermark">
        <div className="trainer_background"></div>
        <Header />
        {/* <h2 className="shady_header">
          <strong className="iconsheading">Become A Trainer Partner</strong>
        </h2> */}
        <div className="trainer_container box-image col-sm-5 col-md-5 col-xl-5 col-lg-5">
          <h3>
              <strong style={{fontSize: "3rem", color: "white"}}>Trainer Registration</strong>
          </h3>
         
          <div className=" trainer_box_mq col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <br />
            {page === 0 && <Typeoftrainer onSubmit={this.nextPage} />}
            {page === 1 && (
               <Success 
               previousPage={this.previousPage}
               onSubmit={this.nextPage}
               />
            )}
            
          </div>
        </div>
        <Footer />

      </div>
    );
  }
}

const mapStateToProps = State => {
  return {
    page: State.trainerInfo.page
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateStep: step => {
      dispatch(updateStep(step));
    },
    storeProfile: data => {
      dispatch(userLoginDetails(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainerRegistration);
