import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createRegisterUser } from "../../actions";
import "../../css/LoginModal.css";
import { Row, Col } from "reactstrap";
import { firebase } from "../firebase/index";
import { ToastContainer, toast, Flip } from "react-toastify";
import { toastConfig } from "../Custom/ToastConfig";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withRouter } from "react-router-dom";
import { functions_for_users } from "../../function_constant";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mobile: "",
      email: "",
      password: ""
    };
    // this.postDatatrail = this.postDatatrail.bind(this);
  }
  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleClick = e => {
    let add,
      self = this;
    if (
      this.state.name.trim() &&
      this.state.mobile.trim() &&
      this.state.email.trim() &&
      this.state.password
    ) {
      add = this.state;
      this.props.onAddPost(add);
      this.postDatatrail();
    }
  };

  postDatatrail() {
    let add = this.state,
      self = this;
    add.user_type = "U";

    return new Promise(function(resolve, reject) {
      fetch('https://fitfinitytrainer.com/admin/api/user/register', {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          
          "Content-Type": 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Accept-Encoding": "gzip"
        },
        body: JSON.stringify(add) // body data type must match "Content-Type" header
      }).then((response) => response.json())
      .then((responseData) => {
        if(responseData.status == "success"){
          let user = responseData; 
          console.log(user);   
          toast("Registered Successfully", toastConfig);
          setTimeout(() => {
            window.location.reload(); // Refresh the page after a delay
        }, 2000);
        }
        if(responseData.errors){  
                const errorData = responseData.errors;
                  Object.keys(errorData).forEach((field) => {
                      errorData[field].forEach((message) => {
                        toast(`${field.charAt(0).toUpperCase() + field.slice(1)}: ${message}`, toastConfig);
                      });
                  });
        }
    
      }).catch(err => {
          toast(err.message, toastConfig);
          reject(err);
        }); // parses response to JSON
    });
  }

  postData(url = ``, data) {
    return new Promise(function(resolve, reject) {
      fetch(url, {
        method: "POST",
        cache: "no-cache",
        // credentials: "same-origin",
        headers: {
          "Content-Type": "text/plain; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          "Accept-Encoding": "gzip"
        },
        body: JSON.stringify(data)
      })
        .then(response => resolve(response.json()))
        .catch(err => {
          toast(err.code, err.message, toastConfig);
          reject(err);
        });
    });
  }
  handleReset = () => {
    this.setState({
      name: "",
      gender: "",
      phoneNumber: "",
      password: "",
      email: ""
    });
  };

  render() {
    return (
      <div>
        <h2 style={{color: "white", textAlign: "center"}}>Register to get fit</h2>
        <Row>
          <Col sm="12">
            <div className="modalcontainer">
              <ValidatorForm
                onSubmit={() => this.handleClick()}
                className="login"
              >
                <div className="form-group">
                    <div className="inputfield">
                    <TextValidator
                      type="text"
                      placeholder="Name *"
                      className="form-control"
                      name="name"
                      onChange={this.handleInputChange}
                      value={this.state.name}
                      validators={["required"]}
                      errorMessages={["Name is required"]}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="inputfield">
                    <TextValidator
                      type="text"
                      placeholder="Phone Number *"
                      className="form-control"
                      name="mobile"
                      onChange={this.handleInputChange}
                      value={this.state.mobile}
                      validators={["required"]}
                      errorMessages={[
                        "Please enter a valid phone number along with code"
                      ]}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="inputfield">
                    <TextValidator
                      type="email"
                      placeholder="Email *"
                      className="form-control"
                      name="email"
                      onChange={this.handleInputChange}
                      value={this.state.email}
                      validators={["required", "isEmail"]}
                      errorMessages={["Valid Email is required"]}
                    />
                  </div>
                </div>
                  <div className="form-group">
                    <div className="inputfield">
                      <TextValidator
                        type="password"
                        className="form-control"
                        placeholder="Password *"
                        name="password"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                        validators={["required"]}
                        errorMessages={[
                          "Please enter a password with 6 or more characters with any 0-9 number included "
                        ]}
                      />
                    </div>
                  </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-2"></div>
                  </div>

                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-sm-2"></div>
                    </div>
                    <center className="bottom">
                  <button type="submit" style={{background: "black", color: "#E9C589"}} className="servie-link button-slanted nav-link">
                    <span className="button-slanted-content">Register</span>
                  </button>
                 
                </center>
                  </div>
                </div>
              </ValidatorForm>
            </div>
          </Col>
        </Row>
        <ToastContainer transition={Flip} />
      </div>
    );
  }
}
const mapStateToProps = State => {
  return { reguser: State.reguser };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddPost: reguser => {
      dispatch(createRegisterUser(reguser));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
