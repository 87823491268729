const InstituteJson1 = [
  {
    id: 1,
    header: "FELLOWSHIP PRORAMMES ",

    duration: "4 week 4 hours",
    Price: "8000",

    subheader2: "Diet & nutrition",
    subheader3: "Soft skill development",
    subheader4: "Principle of KETO diet & alliterative diet",
    subheader5: "Training special population",
    subheader6: "Sports injuries & prevention",
    subheader1: "Obesity Management"
  }
];
export default InstituteJson1;
