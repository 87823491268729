import React, { Component } from "react";
import Header from "../Header";
import { connect } from "react-redux";
import { createReview, changepassword, userLoginDetails } from "../../actions/index";
import { toastConfig } from "../Custom/ToastConfig";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import CustomSpinner from "../Custom/spinner";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer, Flip } from "react-toastify";
const FA = require("react-fontawesome");


class ChangePassword extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          password: "",
          confirm_password: "",
          };
      }

      componentDidMount() {
      }

      handleInputChange_formData = e => {
        const { formData } = this.state;
        formData[e.target.name] = e.target.value;
        this.setState({ formData });
      };

      handleInputChange = e => {
        this.setState({
          [e.target.name]: e.target.value
        });
      };
    
      handleSubmit = e => {
        let data = this.state,
          temp = [];
          let self = this;
    
        if (
          (data.password &&
            data.confirm_password) !== undefined
        ) {

          const searchParams = new URLSearchParams(this.props.location.search);

          console.log(data);
         const id =  searchParams.get('id');
          return new Promise(function(resolve, reject) {
            fetch('https://fitfinitytrainer.com/admin/api/user/changepassword/'+id, {
              method: "POST", // *GET, POST, PUT, DELETE, etc.
              cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
              credentials: "same-origin", // include, *same-origin, omit
              headers: {
                  "Content-Type": 'application/json',
                  "Access-Control-Allow-Origin": "*",
                  "Accept-Encoding": "gzip"
              },
              body: JSON.stringify(data) // body data type must match "Content-Type" header
            }).then((response) => response.json())
            .then((responseData) => {
              if(responseData.status == "success"){
                toast(responseData.message, toastConfig);
                self.handleReset();
                
              }
              //  self.state.aadhar = responseData.file;
          }).catch(err => {
                toast(err.message, toastConfig);
                reject(err);
              }); // parses response to JSON
          });
        } else {
          
    
          toast(
            "Please provide the details for " +
              temp.map(i => {
                return i + " ";
              }),
            toastConfig
          );
        }
      };

      handleReset = () => {
        this.setState({
          password: "",
          confirm_password: "",
        });
      };

      render() {
        return (
            
          <div className="gym-background background-watermark">
                    <Header />
            <div className="background-watermark">
                <div className="trainer_container box-image col-sm-5 col-md-5 col-xl-5 col-lg-5">
                    <div className="trainer_header_container">
                        <ValidatorForm onSubmit={this.handleSubmit}>
                        <div className="form-group ">
                            <div className="inputfield">
                            <TextValidator
                                errorMessages={"Password is required"}
                                type="password"
                                placeholder="Password"
                                className="form-control"
                                name="password"
                                onChange={this.handleInputChange}
                                value={this.state.password}
                            />
                            </div>
                        </div>
                        <div className="form-group ">
                            <div className="inputfield">
                            <TextValidator
                                errorMessages={"Confirm you password"}
                                type="password"
                                placeholder="Confirm password"
                                className="form-control"
                                name="confirm_password"
                                onChange={this.handleInputChange}
                                value={this.state.confirm_password}
                            />
                            </div>
                        </div>
                            <button type="submit" style={{background: "black", color: "#E9C589"}} className="servie-link button-slanted nav-link">Submit</button>
                        </ValidatorForm>
                        <ToastContainer transition={Flip} />
                    </div>
                </div>
                    
                    </div>
          
          </div>
        );
      }

      
}
const mapStateToProps = State => {
    return { userLoginDetails: State.trainerInfo };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      onAddBankDetails: chnagePassword => {
        dispatch(changepassword(chnagePassword));
      }
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
  