const InstituteJson1 = [
  {
    id: 1,
    header: "SPECIALIST PROGRAMME",

    duration: "12 week ",
    Price: "20000",
    subheader1: "Weight Management",
    subheader3: "Fitness Nutrition",
    subheader2: "Sports Conditioning"
  }
];
export default InstituteJson1;
