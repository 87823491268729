export const TimePickJson = {
  '06': "6:00-7:00AM",
  '07': "7:00-8:00AM",
  '08': "8:00-9:00AM",
  '09': "9:00-10:00AM",
  10: "10:00-11:00AM",
  11: "11:00AM-12:00PM",
  12: "12:00-1:00PM",
  13: "1:00-2:00PM",
  14: "2:00-3:00PM",
  15: "3:00-4:00PM",
  16: "4:00-5:00PM",
  17: "5:00-6:00PM",
  18: "6:00-7:00PM",
  19: "7:00-8:00PM",
  20: "8:00-9:00PM",
  21: "9:00-10:00PM"
};
