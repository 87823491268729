import React from 'react';


class ClosedTickets extends React.Component{
    render(){
    return(
        <div>ClosedTickets</div>
    )
    }
}

export default ClosedTickets