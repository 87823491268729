import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { firebase } from "./../firebase";
import { Row, Col } from "reactstrap";
import "../../css/LoginModal.css";
import "../../css/toastify.css";
import "react-toastify/dist/ReactToastify.css";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { ToastContainer, toast, Flip } from "react-toastify";
import { toastConfig } from "../Custom/ToastConfig";
import CustomSpinner from "../Custom/spinner";

class ResetPassword extends Component {
  constructor(props, { match }) {
    super(props, { match });
    this.state = {
      email: "",
      loading: false,
      isSignUpCompleted: false
    };
  }

  handleInputChanged = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleReset = () => {
    this.setState({
      email: "",
    });
  };
  reset_password=()=>{
    this.setState({
      reset:true,
      loading: true
    })
    let self = this;
    // alert(self.state.email);

    let data = {
    "email": self.state.email,
    "password": self.state.password
    }
    return new Promise(function(resolve, reject) {
      fetch('https://fitfinitytrainer.com/admin/api/user/forgotPassword', {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          
          "Content-Type": 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Accept-Encoding": "gzip"
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      }).then((response) => response.json())
      .then((responseData) => {
        if(responseData.status == "success"){
          toast(responseData.message, toastConfig);
          self.handleReset();
          self.setState({
            loading: false
          });
        }
        console.log(responseData.errors);
        if(responseData.errors){  
                const errorData = responseData.errors;
                  Object.keys(errorData).forEach((field) => {
                      errorData[field].forEach((message) => {
                        toast(`${field.charAt(0).toUpperCase() + field.slice(1)}: ${message}`, toastConfig);
                      });
                  });

                  self.setState({
                    loading: false
                  });
        }
        
      
      }).catch(err => {
          toast(err.message, toastConfig);
          reject(err);
        }); // parses response to JSON
    });
    
  }
  render() {
    return (
      <div>
        <Row>
          <Col sm="12">
          <h2 style={{color: "white", textAlign: "center"}}>
          Reset you password
        </h2>
            <div className="modalcontainer ">
              <ValidatorForm>
                <div className="form-group">
                    <div className="inputfield">
                    <TextValidator
                      type="email"
                      className="form-control"
                      placeholder="Enter your registered email"
                      name="email"
                      value={this.state.email}
                      validators={["required", "isEmail"]}
                      errorMessages={["Valid Email is required"]}
                      onChange={this.handleInputChanged}
                    />
                  </div>
                </div>
                <center className="bottom">
                <button type="submit" onClick={()=>this.reset_password()} style={{background: "black", color: "#E9C589"}} className="servie-link button-slanted nav-link">
                Send Rest Password Link
                  </button>
                </center>
              </ValidatorForm>
            </div>
          </Col>
        </Row>

        <ToastContainer transition={Flip} />
        {this.state.loading ? (
          <CustomSpinner
            spinnerVal={true}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter((ResetPassword));
