import React, { Component } from "react";
import Header from "../Header";
import { connect } from "react-redux";
import { createReview, createTrainerBankDetails } from "../../actions/index";
import { toastConfig } from "../Custom/ToastConfig";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import CustomSpinner from "../Custom/spinner";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer, Flip } from "react-toastify";
const FA = require("react-fontawesome");


class UpdateKyc extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            account_no: "",
            confirm_account_no: "",
            account_holder_name: "",
            ifsc_code: "",
            bank_name: "",
            branch_name: "",
            aadhar: "",
            pan: "",
            photo: "",
            certificate: "",
            qualification: "",
            language: "",
            age: "",
          };
      }

      componentDidMount() {
       
       
        
      }

      handleInputChange_formData = e => {
        const { formData } = this.state;
        formData[e.target.name] = e.target.value;
        this.setState({ formData });
      };

      handleInputChange = e => {
        this.setState({
          [e.target.name]: e.target.value
        });
      };

      handleadhhar = e => {
        let self = this;
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      //     const searchParams = new URLSearchParams(this.props.location.search);

      //    const id =  searchParams.get('id');

      //    alert(id);
        return new Promise(function(resolve, reject) {
          fetch('https://fitfinitytrainer.com/admin/api/trainer/imageUpload', {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Accept-Encoding": "gzip"
            },
            body: formData // body data type must match "Content-Type" header
          }).then((response) => response.json())
          .then((responseData) => {
            self.setState({
              aadhar: responseData.file,
          });
            //  self.state.aadhar = responseData.file;
        }).catch(err => {
              toast(err.message, toastConfig);
              reject(err);
            }); // parses response to JSON
        });

  };
      handlepan = e => {
        let self = this;
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      //     const searchParams = new URLSearchParams(this.props.location.search);

      //    const id =  searchParams.get('id');

      //    alert(id);
        return new Promise(function(resolve, reject) {
          fetch('https://fitfinitytrainer.com/admin/api/trainer/imageUpload', {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Accept-Encoding": "gzip"
            },
            body: formData // body data type must match "Content-Type" header
          }).then((response) => response.json())
          .then((responseData) => {
            self.setState({
             pan: responseData.file,
          });
            //  self.state.aadhar = responseData.file;
        }).catch(err => {
              toast(err.message, toastConfig);
              reject(err);
            }); // parses response to JSON
        });

  };
      handlephoto = e => {
        let self = this;
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      //     const searchParams = new URLSearchParams(this.props.location.search);

      //    const id =  searchParams.get('id');

      //    alert(id);
        return new Promise(function(resolve, reject) {
          fetch('https://fitfinitytrainer.com/admin/api/trainer/imageUpload', {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Accept-Encoding": "gzip"
            },
            body: formData // body data type must match "Content-Type" header
          }).then((response) => response.json())
          .then((responseData) => {
            self.setState({
              photo: responseData.file,
          });
            //  self.state.aadhar = responseData.file;
        }).catch(err => {
              toast(err.message, toastConfig);
              reject(err);
            }); // parses response to JSON
        });

  };
      handlecerti = e => {
        let self = this;
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      //     const searchParams = new URLSearchParams(this.props.location.search);

      //    const id =  searchParams.get('id');

      //    alert(id);
        return new Promise(function(resolve, reject) {
          fetch('https://fitfinitytrainer.com/admin/api/trainer/imageUpload', {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Accept-Encoding": "gzip"
            },
            body: formData // body data type must match "Content-Type" header
          }).then((response) => response.json())
          .then((responseData) => {
            self.setState({
              certificate: responseData.file,
          });
            //  self.state.aadhar = responseData.file;
        }).catch(err => {
              toast(err.message, toastConfig);
              reject(err);
            }); // parses response to JSON
        });

  };
      handleUpload = e => {
        e.preventDefault();
        if (
          this.state.aadhar !== undefined &&
          this.state.pan !== undefined &&
          this.state.photo !== undefined &&
          this.state.certificate !== undefined
        ) {

        } else {
          toast("Please provide images of all the documents !!", toastConfig);
        }
      };
    
      handleSubmit = e => {
        let data = this.state,
          temp = [];
          let self = this;

          // console.log(data);
    
        if (
          (data.account_no &&
            data.confirm_account_no &&
            data.account_holder_name &&
            data.ifsc_code &&
            data.bank_name &&
            data.branch_name) !== undefined
        ) {

          const searchParams = new URLSearchParams(this.props.location.search);

         const id =  searchParams.get('id');
          return new Promise(function(resolve, reject) {
            fetch('https://fitfinitytrainer.com/admin/api/trainer/updateKyc/'+id, {
              method: "PATCH", // *GET, POST, PUT, DELETE, etc.
              cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
              credentials: "same-origin", // include, *same-origin, omit
              headers: {
                  "Content-Type": 'application/json',
                  "Access-Control-Allow-Origin": "*",
                  "Accept-Encoding": "gzip"
              },
              body: JSON.stringify(data) // body data type must match "Content-Type" header
            }).then((response) => response.json())
            .then((responseData) => {

              if(responseData.status == "success"){
                toast('KYC Done Succesfully', toastConfig);
                self.handleReset();
              }
              if(responseData.errors){  
                      const errorData = responseData.errors;
                        Object.keys(errorData).forEach((field) => {
                            errorData[field].forEach((message) => {
                              toast(`${field.charAt(0).toUpperCase() + field.slice(1)}: ${message}`, toastConfig);
                            });
                        });
              }
              
          }).catch(err => {
                toast(err.message, toastConfig);
                reject(err);
              }); // parses response to JSON
          });
        } else {
          
    
          toast(
            "Please provide the details for " +
              temp.map(i => {
                return i + " ";
              }),
            toastConfig
          );
        }
      };

      handleReset = () => {
        this.setState({
          account_no: "",
          confirm_account_no: "",
          account_holder_name: "",
          ifsc_code: "",
          bank_name: "",
          branch_name: "",
          aadhar: "",
          pan: "",
          photo: "",
          certificate: "",
          qualification: "",
          language: "",
          age: "",
        });
      };

      render() {
        return (
            
          <div className="login">
            <div className="background-watermark">
                <div className="trainer_container box-image col-sm-5 col-md-5 col-xl-5 col-lg-5">
                    <div className="trainer_header_container">
                        <ValidatorForm onSubmit={this.handleSubmit}>
                        <div className="form-group ">
                            <div className="inputfield">
                            <TextValidator
                                errorMessages={"Please input your Age !"}
                                type="text"
                                placeholder="Age *"
                                className="form-control"
                                name="age"
                                onChange={this.handleInputChange}
                                value={this.state.age}
                            />
                            </div>
                        </div>
                        <div className="form-group ">
                            <div className="inputfield">
                            <TextValidator
                                errorMessages={"Please input your language !"}
                                type="text"
                                placeholder="Language *"
                                className="form-control"
                                name="language"
                                onChange={this.handleInputChange}
                                value={this.state.language}
                            />
                            </div>
                        </div>
                        <div className="form-group ">
                            <div className="inputfield">
                            <TextValidator
                                errorMessages={"Please input your qualification !"}
                                type="text"
                                placeholder="Qualification *"
                                className="form-control"
                                name="qualification"
                                onChange={this.handleInputChange}
                                value={this.state.qualification}
                            />
                            </div>
                        </div>
                        <div className="form-group ">
                            <div className="inputfield">
                            <TextValidator
                                errorMessages={"Please input your Account Holder name !"}
                                type="text"
                                placeholder="Account Holder Name *"
                                className="form-control"
                                name="account_holder_name"
                                onChange={this.handleInputChange}
                                value={this.state.account_holder_name}
                            />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="inputfield">
                            <TextValidator
                                type="number"
                                className="form-control"
                                placeholder="Account No *"
                                name="account_no"
                                onChange={this.handleInputChange}
                                value={this.state.account_no}
                                errorMessages={[
                                "Please provide valid account number",
                                "This field is mandatory"
                                ]}
                            />
                            </div>
                        </div>
                
                        <div className="form-group">
                            <div className="inputfield">
                            <TextValidator
                                type="number"
                                className="form-control"
                                name="confirm_account_no"
                                placeholder="Confirm Account No *"
                                onChange={this.handleInputChange}
                                value={
                                this.state.confirm_account_no
                                }
                                errorMessages={[
                                "Account Number mismatch",
                                "This field is mandatory"
                                ]}
                            />
                            </div>
                        </div>
                
                        <div className="form-group">
                            <div className="inputfield">
                            <TextValidator
                                placeholder="IFSC Code *"
                                className="form-control"
                                name="ifsc_code"
                                onChange={this.handleInputChange}
                                value={this.state.ifsc_code}
                                type="text"
                                pattern="/^-?\d+\.?\d*$/"
                                errorMessages={[
                                "Please enter valid IFSC Code",
                                "Please enter your IFSC Code !"
                                ]}
                            />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="inputfield">
                            <TextValidator
                                type="text"
                                placeholder="Bank Name *"
                                className="form-control"
                                name="bank_name"
                                onChange={this.handleInputChange}
                                value={this.state.bank_name}
                                errorMessages={"This field is mandatory !"}
                            />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="inputfield">
                            <TextValidator
                                type="text"
                                placeholder="Branch Name *"
                                className="form-control"
                                name="branch_name"
                                onChange={this.handleInputChange}
                                value={this.state.branch_name}
                                errorMessages={"This field is mandatory !"}
                            />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-2" style={{ color: "grey" }}>
                                Aadhar:
                                </div>
                                <div
                                className="col-sm-6 file-input"
                                style={{ display: "flex" }}
                                >
                                <input
                                    id="file"
                                    type="file"
                                    name="aadhar"
                                    className="custom-file-input"
                                    onChange={this.handleadhhar}
                                />
                                <label for="file" className="btn-2">
                                    Choose a file
                                </label>
                                </div>
                                <p><img style={{width:"20%"}} src={this.state.aadhar ? this.state.aadhar : null}></img></p>
                            </div>
                            </div>
                            <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2" style={{ color: "grey" }}>
                      Pan:
                    </div>
                    <div
                      className="col-sm-6 file-input"
                      style={{ display: "flex" }}
                    >
                      <input
                        type="file"
                        id="pan"
                        name="pan"
                        className="custom-file-input"
                        onChange={this.handlepan}
                      />
                      <label for="pan" className="btn-2" id="pan">
                        Choose a file
                      </label>
                    </div>
                    <p><img style={{width:"20%"}} src={this.state.pan ? this.state.pan : null}></img></p>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2" style={{ color: "grey" }}>
                      Photo:
                    </div>
                    <div
                      className="col-sm-6 file-input"
                      style={{ display: "flex" }}
                    >
                      <input
                        type="file"
                        id="photo"
                        name="photo"
                        className="custom-file-input"
                        onChange={this.handlephoto}
                      />
                      <label for="photo" className="btn-2" id="photo">
                        Choose a file
                      </label>
                    </div>
                    <p><img style={{width:"20%"}} src={this.state.photo ? this.state.photo : null}></img></p>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2" style={{ color: "grey" }}>
                      Certificate:
                    </div>

                    <div
                      className="col-sm-6 file-input"
                      style={{ display: "flex" }}
                    >
                      <input
                        type="file"
                        id="certificate"
                        name="certificate"
                        className="custom-file-input"
                        onChange={this.handlecerti}
                      />
                      <label for="certificate" className="btn-2" id="certificate">
                        Choose a file
                      </label>
                    </div>
                    <p><img style={{width:"20%"}} src={this.state.certificate ? this.state.certificate : null}></img></p>
                  </div>
                  </div>

                  <button type="submit" style={{background: "black", color: "#E9C589"}} className="servie-link button-slanted nav-link">
                Submit
              </button>
                        </ValidatorForm>
                        <ToastContainer transition={Flip} />
                    </div>
                </div>
                    
                    </div>
          
          </div>
        );
      }

      
}
const mapStateToProps = State => {
    return { trainer: State.trainerInfo };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      onAddBankDetails: bankDetails => {
        dispatch(createTrainerBankDetails(bankDetails));
      }
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(UpdateKyc);
  