import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "../../css/Howitcomponent.css";


class HowitWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  Proceed = () => {
    if (localStorage.getItem('token')) {
      this.props.history.push("/bookSession");
    } else {
      this.props.history.push("/login");
    }
  };
  Proceed1 = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div className="fitnesscontainer_two" style={{ minWidth: "250px", padding: "0px 20px"}}>
        <ul
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            margin: "14px",
            lineHeight: "1.5rem",
            

          }}
        >
          <li style={{ textAlign: "justify", color: "#E9C589 !important"}}>Choose the service</li>
          <li style={{ textAlign: "justify", color: "#E9C589 !important" }}>Book Trial Session at 99/-(Refundable*)
          </li>
          <li style={{ textAlign: "justify", color: "#E9C589 !important" }}>Make payment and sit back{" "}</li>
          <li style={{ textAlign: "justify", color: "#E9C589 !important" }}>We will send our best professional. If you like the session go ahead with our suitable package.
          </li>
        </ul>
        <div className="buttonalignment">
          <button
            style={{background: "black", color: "#E9C589"}} className="servie-link button-slanted nav-link"
            onClick={() => this.props.closehowitworks()}
          >
            cancel
          </button>
          <button
            style={{background: "black", color: "#E9C589"}} className="servie-link button-slanted nav-link"
            onClick={() => this.Proceed()}
          >
            proceed
          </button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    service: state.serviceState.services
  };
};

export default withRouter(connect(mapStateToProps, null)(HowitWorks));
