import React from 'react';

class FoodZone extends React.Component{
    render()
    {
        return(
            <div>
                <p> I am  FoodZone Trainer</p>
            </div>
        )
    }
}
export default FoodZone;